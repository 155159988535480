<template>
  <div id="learnComponent">
    <div :class="!isApp && type != 2 ? 'appTop' : ''">
      <!-- 修炼页面type1展示 -->
      <van-tabs
        v-if="type == 1"
        @click="onChangeTab"
        :color="'rgba(255, 255, 255, 0.7)'"
        background="#1C172A"
        line-width="22"
        :title-active-color="'#fff'"
        v-model="nameType"
      >
        <van-tab title="单品修炼" :name="1"> </van-tab>
        <van-tab title="组合修炼" :name="2"></van-tab>
      </van-tabs>
      <!-- 派遣修炼type2展示（单品时候） -->
      <div class="header" :class="!isApp ? 'appTop' : ''" v-if="type == 2">
        <div class="icon">
          <van-icon
            @click="chooseAllChange"
            :name="chooseAll ? coll_choose : coll_no_choose"
            class=""
          /><span>全选</span>
        </div>
        <div class="only">仅展示可派遣修炼的藏品</div>
      </div>
      <div class="main" v-if="nameType == 1">
        <div class="" v-for="(item, index) in myList" :key="index">
          <div class="items" v-if="item.items.length">
            <div class="title">
              <div class="name">《{{ item.display_name }}》{{ item.name }}</div>
              <div class="icon" v-if="type != 1">
                <van-icon
                  @click="chooseItem(index)"
                  :name="item.checked ? coll_choose : coll_no_choose"
                  class=""
                /><span>全选</span>
              </div>
            </div>
            <div class="card-box">
              <div class="card-item" v-for="(subitem, subindex) in item.items" :key="subindex">
                <div class="card">
                  <img :src="subitem.image_icon" alt="" />
                  <img class="levelIcon" :src="levelsChange(subitem.levels)" />
                  <div class="icon">
                    <!-- :name="item.cheacked ? coll_choose : coll_no_choose" -->
                    <van-icon
                      :name="subitem.checked ? coll_choose : coll_no_choose"
                      v-if="
                        type == 2 ||
                        (type == 3 && subitem.status == 1 && diffTime(subitem.end_time) > 0)
                      "
                      class=""
                      @click="addCheck(index, subindex)"
                    />
                  </div>
                </div>

                <div
                  class="meng t-c"
                  v-if="type != 2 && subitem.status == 1 && diffTime(subitem.end_time) < 0"
                >
                  <div class="point">{{ subitem.points }} <span>积分</span></div>
                  <div class="btn" @click="getPoint(subitem.user_collection_id)">领取</div>
                </div>
                <div class="tips" v-if="type != 2">
                  <span v-if="subitem.status == 1 && diffTime(subitem.end_time) < 0"
                    >修炼已结束</span
                  >
                  <div class="" v-if="subitem.status == 1 && diffTime(subitem.end_time) > 0">
                    <div class="" v-if="diffTime(subitem.end_time) > 24 * 60 * 60 * 1000">
                      {{ formatDuring(diffTime(subitem.end_time)) }}
                    </div>
                    <div class="countdown" v-else>
                      <van-count-down
                        class=""
                        :time="diffTime(subitem.end_time)"
                        @finish="getList"
                      />结束
                    </div>
                  </div>
                </div>
                <div class="t-c num">编号：{{ subitem.serial_num }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 组合修炼 -->
      <div class="main" v-if="nameType == 2">
        <div class="" v-for="(item, index) in myComList" :key="index">
          <div class="items">
            <div class="title">
              <div class="name">组合修炼{{ index + 1 }}</div>
              <div class="icon" v-if="type != 1">
                <van-icon
                  @click="chooseItem(index)"
                  :name="item.checked ? coll_choose : coll_no_choose"
                  class=""
                /><span>选择</span>
              </div>
            </div>
            <div class="card-box">
              <div class="card-item">
                <div class="card">
                  <img :src="item.result_item.image_icon" alt="" />
                  <img class="levelIcon" :src="levelsChange(item.result_item.levels)" />
                </div>

                <div
                  class="meng t-c"
                  v-if="
                    type != 2 &&
                    item.result_item.status == 1 &&
                    diffTime(item.result_item.end_time) < 0
                  "
                >
                  <div class="point">{{ item.result_item.points }} <span>积分</span></div>
                  <div class="btn" @click="getPoint(item.result_item.user_collection_id)">领取</div>
                </div>
                <div class="tips" v-if="type != 2">
                  <span
                    v-if="item.result_item.status == 1 && diffTime(item.result_item.end_time) < 0"
                    >修炼已结束</span
                  >
                  <div
                    class=""
                    v-if="item.result_item.status == 1 && diffTime(item.result_item.end_time) > 0"
                  >
                    <div class="" v-if="diffTime(item.result_item.end_time) > 24 * 60 * 60 * 1000">
                      {{ formatDuring(diffTime(item.result_item.end_time)) }}
                    </div>
                    <div class="countdown" v-else>
                      <van-count-down
                        class=""
                        :time="diffTime(item.result_item.end_time)"
                        @finish="getList"
                      />结束
                    </div>
                  </div>
                </div>
                <div class="t-c num">编号：{{ item.result_item.serial_num }}</div>
              </div>

              <div class="card-item">
                <div class="card">
                  <img :src="item.equity_item.image_icon" alt="" />
                  <img class="levelIcon" :src="levelsChange(item.equity_item.levels)" />
                </div>

                <div class="meng t-c comMeng">
                  <img src="../../../imgs/learn/fastIcon.png" alt="" />
                </div>
                <div class="tips" v-if="type != 2">
                  <span v-if="diffTime(item.equity_item.end_time) < 0">修炼已结束</span>
                  <div class="" v-if="diffTime(item.equity_item.end_time) > 0">
                    <div class="" v-if="diffTime(item.equity_item.end_time) > 24 * 60 * 60 * 1000">
                      {{ formatDuring(diffTime(item.equity_item.end_time)) }}
                    </div>
                    <div class="countdown" v-else>
                      <van-count-down
                        class=""
                        :time="diffTime(item.equity_item.end_time)"
                        @finish="getList"
                      />结束
                    </div>
                  </div>
                </div>
                <div class="t-c num">编号：{{ item.equity_item.serial_num }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="one-get" v-if="type == 1 && !greOne" :style="{ opacity: greOne ? 0.7 : 1 }">
        <div class="nei" @click="getPoint(0)">一键<br />领取</div>
      </div>
      <div class="footer">
        <div class="type1" v-if="type == 1">
          <div class="btn" v-if="nameType == 1" @click="sendLearn">派遣修炼</div>
          <div class="btn" v-if="nameType == 2" @click="sendLearn">组合修炼</div>
          <div class="btn" @click="stopLearn">终止修炼</div>
        </div>
        <div class="type3" v-if="type == 3">
          <div class="icon">
            <van-icon
              @click="chooseAllChange"
              :name="chooseAll ? coll_choose : coll_no_choose"
            /><span>全选</span>
          </div>
          <div class="btn" :style="{ opacity: checkedArr.length ? 1 : 0.7 }" @click="showStopMeth">
            终止修炼({{ checkedArr.length }})
          </div>
        </div>
        <div class="type2" v-if="type == 2">
          <div class="bt-left">
            <div class="choose" @click="showpicker = true">
              <span class="words"> 修炼{{ days }}天</span>
              <div class="icon"><van-icon :name="icon_up_d" class="" /></div>
            </div>
            <div class="point" @click="knownBack = true">
              可获得<span>{{ showPoint }}</span
              >积分<van-icon name="question-o" />
            </div>
          </div>
          <div class="btn" :style="{ opacity: checkedArr.length ? 1 : 0.7 }" @click="startLesrning">
            开始修炼({{ checkedArr.length }})
          </div>
        </div>
      </div>
      <div v-if="showNull" class="dataNullBox">
        <div>
          <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">
          暂无数据 <van-icon v-if="type == 2" @click="knownBack = true" name="question-o" />
        </div>
        <div class="icons" v-if="type != 2" @click="sendLearn">
          去派遣 <van-icon name="arrow" />
        </div>
      </div>
      <popup
        v-model:show.sync="showstop"
        title="终止修炼"
        :desc="`是否终止正在修炼的${checkedArr.length}个藏品？终止后将不可获得完成修炼的${stopPoint}积分哦~`"
        :btncon="{ cancel: '取消', success: '终止' }"
        @confirm="stopClick"
        @cancel="showstop = false"
      />
      <div class="" id="invite-award">
        <van-popup class="vantPop" v-model:show="knownBack">
          <div class="popup">
            <div class="content">
              <div class="title">闭关修炼说明</div>
              <div class="nei">
                <p>
                  1、本次参与闭关修炼的藏品：《仙武帝尊》「东荒女帝」、「荒古大帝」「叶辰」、「姬凝霜」，《盖世帝尊》赤火灵鸟；
                </p>
                <p>2、闭关修炼期间藏品不可转赠、不可合成，但不影响藏品本身的权益；</p>
                <p>3、闭关修炼期间可随时结束修炼，但当次修炼不会获得积分；</p>
                <p>4、闭关修炼得积分规则</p>
                <img src="https://activity-resource.321mh.com/nft/xiulian/legend_epic.png" alt="" />
              </div>
            </div>
            <div class="btn">
              <div @click.stop="knownBack = false">知道了</div>
            </div>
          </div>
        </van-popup>
      </div>
      <PageLoading :show="pageLoading" />
      <van-action-sheet
        v-model:show="showpicker"
        :actions="columns"
        @select="pickerChange"
        cancel-text="取消"
        @cancel="showpicker = false"
      />
      <!-- <van-picker v-show="showpicker" @cancel="showpicker = false" :columns="columns" /> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { nftUtils } from '@/utils';
import moment from 'moment';
import popup from '@/components/popup/index.vue';
import PageLoading from '@/components/loading/PageLoading.vue';
import {
  getcultivatingcollections,
  getwaitregularList,
  stopregular,
  collectionregular,
  regularconfig,
  receivescores,
  getstopregularlist,
  getcomcultivatingcollections,
  getcomstopregularlist
} from '@/service/learn';
import { Toast } from 'vant';
export default {
  components: {
    popup,
    PageLoading
  },
  props: {
    type: {
      type: Number,
      default: 1 //1--闭关修炼页面，2--派遣页面，3--终止页面
    },
    list: {
      type: Array
    }
  },

  setup(props, ctx) {
    const router = useRouter();
    const state = reactive({
      myList: [], // props.list,
      isApp: nftUtils.isInApp(),
      columns: [],
      showpicker: false,
      server_time: 0,
      id_list: [], //选中派遣的id
      days: null,
      showPoint: 0,
      configList: [],
      checkedArr: [],
      chooseAll: false,
      showstop: false,
      stopPoint: 0,
      showNull: true,
      pageLoading: true,
      knownBack: false,
      greOne: true,
      /*************************************** */
      nameType: 1,
      myComList: []
    });
    // watch(
    //   () => props.list,
    //   list => {
    //     state.myList = list;
    //   }
    // );
    onMounted(async () => {
      if (state.nameType == 1) {
        getList();
      } else {
        getComList();
      }

      // getComList();
      props.type == 2 && getBasic();
    });

    const getComList = async () => {
      let comPostMeth = getcomcultivatingcollections;
      const { data, server_time } = await comPostMeth();
      state.server_time = server_time;
      state.myComList = data;
      state.pageLoading = false;
      state.showNull = false;
      if (!state.myComList.length) {
        state.showNull = true;
      }

      state.greOne = true;
      state.myComList.map(item => {
        console.log(diffTime(item.result_item.end_time));
        if (item.result_item.status == 1 && diffTime(item.result_item.end_time) < 0) {
          // 一键领取
          state.greOne = false;
        }
      });
    };
    // 获取基础配置
    const getBasic = async () => {
      const { data } = await regularconfig();
      state.configList = data;
      // 获取可选择天数配置
      state.configList.map(item => {
        state.columns.push({ id: item.days, name: `修炼${item.days}天` });
      });

      let map = new Map();
      for (let item of state.columns) {
        if (!map.has(item.id)) {
          map.set(item.id, item);
        }
      }
      state.columns = [...map.values()];
      state.days = state.columns[0].id;
    };

    const showStopMeth = () => {
      if (state.checkedArr.length) {
        state.showstop = true;
      }
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      let mill = moment(moment(time)).diff(moment(state.server_time), 'millisecond');

      return moment(moment(time)).diff(moment(state.server_time), 'millisecond');
    };

    // 积分领取
    const getPoint = async id => {
      let postObj = id == 0 ? { type: state.nameType } : { type: state.nameType, id_list: [id] };
      const { data, message, status } = await receivescores(postObj);

      if (status == 0) {
        state.nameType == 1 ? getList() : getComList();
        Toast('领取成功');
      } else {
        Toast(message);
      }
    };

    //停止修炼
    const stopClick = async () => {
      let id_list = state.checkedArr.map(item => {
        return item.user_collection_id;
      });
      const { data, message, status } = await stopregular({ id_list });
      if (status == 0) {
        getList();
        state.checkedArr = [];
        state.showstop = false;
      } else {
        Toast(message);
      }
    };

    // 开始修炼
    const startLesrning = async () => {
      if (!state.checkedArr.length) {
        return;
      }
      let id_list = state.checkedArr.map(item => {
        return item.user_collection_id;
      });
      const { data, message, status } = await collectionregular({ id_list, days: state.days });
      if (status == 0) {
        // getList();
        Toast(state.checkedArr.length + '个藏品已开始修炼');
        router.push({ path: '/Learning/retreat', replace: true });
      } else {
        Toast(message);
      }
    };

    // 选择时间类型切换
    const pickerChange = ({ id, name }) => {
      state.days = id;
      computedPoint();
      state.showpicker = false;
    };

    // 全选
    const chooseAllChange = () => {
      state.chooseAll = !state.chooseAll;
      state.myList.map(v => {
        v.checked = state.chooseAll;
        v.items.map(i => {
          i.checked = state.chooseAll;
        });
      });
      asetCard();
    };

    // 重置已选
    const asetCard = () => {
      // 重置选择的藏品
      state.checkedArr = [];
      state.stopPoint = 0;
      state.myList.forEach(v => {
        v.items.forEach(i => {
          // 判断是否为终止派遣，需要过滤掉以及完成修炼的藏品
          if (props.type == 3) {
            if (i.checked && i.status == 1 && diffTime(i.end_time) > 0) {
              state.checkedArr.push(i);
              state.stopPoint += i.points;
            }
          } else {
            // 派遣页面不需过滤
            if (i.checked) {
              state.checkedArr.push(i);
            }
          }
        });
      });
      computedPoint();
    };

    // 选择二级分类所有藏品
    const chooseItem = index => {
      let item = state.myList[index];
      console.log(item.checked);
      state.myList[index].checked = !item.checked;
      // 同步子类选择状态
      state.myList[index].items.map(v => {
        v.checked = item.checked;
      });
      // 重置选择的藏品

      asetCard();
    };

    //选择藏品
    const addCheck = (index, subindex) => {
      let item = state.myList[index].items[subindex];
      state.myList[index].items[subindex].checked = !item.checked;
      console.log(item.checked, '...');
      if (!item.checked) {
        state.myList[index].checked = false;
        state.chooseAll = false;
      }
      asetCard();
      // if (item.checked) {
      //   state.checkedArr.push(item);
      // } else {
      //   // 取消选择
      //   let delindex;
      //   state.checkedArr.map((v, i) => {
      //     if (v.user_collection_id == item.user_collection_id) {
      //       delindex = i;
      //     }
      //   });
      //   state.checkedArr.splice(delindex, 1);
      // }
      // console.log(state.checkedArr);
      // computedPoint();
    };

    // 计算积分
    const computedPoint = () => {
      state.showPoint = 0;
      state.checkedArr.map(item => {
        state.configList.forEach(element => {
          // && element.type == 1
          if (
            element.token_id == item.token_id &&
            element.days == state.days &&
            element.type == 1
          ) {
            state.showPoint += element.points;
          }
        });
      });
    };

    // 获取列表
    const getList = async () => {
      let postMeth =
        props.type == 2
          ? getwaitregularList
          : props.type == 3
          ? getstopregularlist
          : getcultivatingcollections;
      const { data, server_time } = await postMeth();
      state.server_time = server_time;
      state.myList = data;
      state.pageLoading = false;
      // if (props.type !== 1) {
      // 添加选择
      state.showNull = true;
      state.greOne = true;
      state.myList = state.myList.map(item => {
        if (item.items.length) {
          state.showNull = false;
        }
        item.items = item.items.map(subitem => {
          console.log(diffTime(subitem.end_time));
          if (subitem.status == 1 && diffTime(subitem.end_time) < 0) {
            // 一键领取
            state.greOne = false;
          }
          return { ...subitem, checked: false };
        });
        return { ...item, checked: false };
      });

      // }
    };

    // 倒计时大于1天
    const formatDuring = millisecond => {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24));
      var hours = parseInt((millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      return days + '天' + hours + '小时' + '结束';
    };
    const sendLearn = () => {
      router.push({ path: state.nameType == 1 ? '/Learning/send' : '/Learning/sendGroup' });
    };
    const stopLearn = () => {
      router.push({ path: state.nameType == 1 ? '/Learning/stop' : '/Learning/stopGroup' });
    };
    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    /*************************************** */

    // tab切换
    const onChangeTab = name => {
      state.nameType = name;
      console.log(state.nameType);
      if (name == 1) {
        getList();
      } else {
        getComList();
      }
    };

    return {
      ...toRefs(state),
      levelsChange,
      sendLearn,
      stopLearn,
      getList,
      addCheck,
      diffTime,
      formatDuring,
      pickerChange,
      getBasic,
      computedPoint,
      chooseItem,
      chooseAllChange,
      startLesrning,
      asetCard,
      stopClick,
      showStopMeth,
      getPoint,
      coll_no_choose: require('@/imgs/icon/coll_no_choose.png'),
      coll_choose: require('@/imgs/icon/coll_choose.png'),
      icon_up_d: require('@/imgs/icon/icon_up_down.png'),
      onChangeTab
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/mixin.scss';

.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
    .nei {
      // max-height: 350px;
      // overflow: auto;
      img {
        width: 100%;
      }
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .icons {
    font-size: 14px;
    color: $primary;
    margin-top: 2px;
  }
}
.countdown {
  display: flex;
  align-items: center;
  line-height: 14px;
  --van-count-down-text-color: #fff;
  --van-count-down-font-size: 10px;
  --van-count-down-line-height: 14px;
}
.one-get {
  width: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
  color: #003952;

  background: linear-gradient(180deg, #98fff9 0%, #33c2ff 100%);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  position: fixed;
  right: 16px;
  bottom: 84px;

  .nei {
    width: 56px;
    height: 56px;
    border: 4px solid #00aaf2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;

    background: linear-gradient(225deg, #53cbff 89%, #00aaf2 100%);
  }
}
.footer {
  position: fixed;
  bottom: 0;
  padding: 16px;
  background: #29233a;
  width: 100%;
  border-top: #494359 1px solid;
  box-sizing: border-box;
  z-index: 999;
  .type1 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 0 50px;
    .btn {
      padding: 6px 21px;
    }
  }
  .type3 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    .icon {
      font-size: 24px;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        margin-left: 3px;
      }
    }
  }
  .type2 {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    .bt-left {
      font-size: 12px;
      display: flex;
      align-items: center;
      .choose {
        display: flex;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #9991af;
        align-items: center;
        margin-right: 7px;
        .words {
          padding: 0 4px;
        }
        .icon {
          background: #9991af;
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 3px;
        }
      }
      .point {
        span {
          color: $primary;
        }
      }
    }
  }
  .btn {
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    padding: 6px 7px;
    font-size: 16px;
    color: #1c172a;
    font-weight: 500;
  }
}
.appTop {
  padding-top: 70px !important;
}
.padtop66 {
  padding-top: 66px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  //  box-sizing: border-box;
  // width: 100%;
  align-items: center;
  font-size: 12px;
  height: 56px;
  // position: fixed;
  // top: 0;
  // z-index: 99;
  background: #29233a;
  padding: 0 16px;
  .icon {
    font-size: 24px;
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      margin-left: 3px;
    }
  }
  .only {
    color: rgba(255, 255, 255, 0.7);
  }
}
.t-c {
  text-align: center;
}
.main {
  padding: 16px 0 66px 16px;
  box-sizing: border-box;
  .items {
    margin-bottom: 23px;
  }
  .title {
    font-size: 16px;
    //  margin-left: 8px;
    position: relative;
    margin: 11px 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .icon {
      font-size: 24px;
      margin-right: 16px;
      display: flex;
      align-items: center;
    }
    .name {
      margin-left: 10px;
    }
    span {
      font-size: 12px;
      margin-left: 3px;
      font-weight: 400;
    }
    &::before {
      content: '';
      width: 4px;
      height: 15px;
      background: #58dedd;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
  .card-box {
    display: flex;
    overflow: auto;
    .card-item {
      padding: 8px 7px 5px;
      background: #2b243f;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #494359;
      margin-right: 12px;
      position: relative;
      .tips {
        font-size: 10px;
        background: #000000;
        padding: 1px 2px;
        border-radius: 4px;
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
      }
      .meng {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 4px 4px;
        background: rgba(0, 0, 0, 0.7);
        font-size: 16px;
        .point {
          margin: 40px 0 10px;

          font-weight: 500;
          color: #58dedd;
          span {
            font-size: 10px;
            color: #ffffff;
          }
        }

        .btn {
          width: 60px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
          border-radius: 24px 24px 24px 24px;
          color: #000000;
          font-weight: 500;
          font-size: 12px;
          margin: 0 auto;
        }
      }

      .comMeng {
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 58px;
          height: 22px;
        }
      }
      .card {
        font-size: 0px;
        position: relative;
        img {
          // min-width: 79px;
          width: 79px;
        }
        .levelIcon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
          width: 43px !important;
          // height: auto;
        }
        .icon {
          position: absolute;
          font-size: 22px;
          top: 0;
          right: 0;
          z-index: 10;
        }
      }
      .num {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
#learnComponent {
  .van-tabs__line {
    background-color: #30daec !important;
  }
}
</style>
